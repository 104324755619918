import styled from 'styled-components';

export const BodyContainer = styled.div`
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const Body = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 70px 0 220px 0;
  @media (max-width: 767px) {
    width: 100%;
    padding: 50px 35px 0px 35px;
  }
`;

export const HeaderH3 = styled.h3`
  padding: 0;
  margin: 0 0 40px 0;
  font-family: 'Figtree-Black', arial, sans-serif;
  font-size: 35px;
  line-height: 42px;
  text-transform: uppercase;
  color: #6e2b8b;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const RowStyle = styled.div`
  margin-bottom: 60px;
  @media (max-width: 767px) {
    margin-bottom: 35px;
  }
`;

export const ColumnStyle = styled.div`
  float: left;
  width: 250px;
  @media (max-width: 767px) {
    float: none;
    width: 100%;
  }
`;

export const ParaText = styled.p`
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  padding: 0;
  margin: 0 0 40px 0;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const HeaderH2 = styled.h2`
  padding: 15px 20px 20px 20px;
  font-family: 'Figtree-Black', arial, sans-serif;
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  color: #fec119;
  background: #6e2b8b;
  border-radius: 5px;
  code {
    display: inline-block;
    margin-bottom: 5px;
    font-family: 'Figtree-Bold', arial, sans-serif;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
  }
`;

export const OrDiv = styled.div`
  float: left;
  width: 60px;
  height: 60px;
  padding-top: 19px;
  margin: 35px 20px 0 20px;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  color: #6e2b8b;
  border: 2px solid #6e2b8b;
  border-radius: 50%;
  @media (max-width: 767px) {
    float: none;
    margin: 35px auto;
  }
`;

export const ImageStyle = styled.img`
  width: 200px;
  height: auto;
  margin: 0 0 30px 0;
`;
